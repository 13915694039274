document.addEventListener('DOMContentLoaded', function() {
  const dropzone = document.getElementById('image-dropzone');
  const fileInput = dropzone.querySelector('.file-input');
  const preview = dropzone.querySelector('.preview-image');

  // Prevent default drag behaviors
  ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    dropzone.addEventListener(eventName, e => {
      e.preventDefault();
      e.stopPropagation();
    });
  });

  // Visual feedback
  dropzone.addEventListener('dragenter', () => dropzone.classList.add('drag-hover'));
  dropzone.addEventListener('dragleave', () => dropzone.classList.remove('drag-hover'));
  dropzone.addEventListener('drop', () => dropzone.classList.remove('drag-hover'));

  // Handle drops
  dropzone.addEventListener('drop', e => {
    fileInput.files = e.dataTransfer.files;
    showPreview(e.dataTransfer.files[0]);
  });

  // Handle clicks
  dropzone.addEventListener('click', () => fileInput.click());

  // Handle file input change
  fileInput.addEventListener('change', e => {
    showPreview(e.target.files[0]);
  });

  function showPreview(file) {
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = e => {
        preview.innerHTML = `<img src="${e.target.result}" style="max-width: 200px; max-height: 200px;">`;
      };
      reader.readAsDataURL(file);
    }
  }
}); 